// Remove our preload class from the body, preventing early CSS transitions
import '@utils/preload';

// CSS needs to be imported here as it's treated as a module
import '@/scss/style.scss';

// ================================================
// All third-party, globally-required libraries should be loaded here
// ================================================

// Support for lazyload images (and bg images)
import 'lazysizes';
import 'lazysizes/plugins/unveilhooks/ls.unveilhooks';
import 'lazysizes/plugins/bgset/ls.bgset';

// Flickity - please use `data-flickity` options to configure
import 'flickity';
import 'flickity-bg-lazyload';

// An excellent, responsive and lightweight lightbox
import 'spotlight.js';


// ================================================
// SUI Components
// ================================================

import MobileNav from '@sgroup/mobile-nav';
import ClickToggle from '@sgroup/click-toggle';


// ================================================
// Setup Vue3 - Use only if this project needs Vue!
// ================================================
import { createApp } from 'vue';

// Import all globally-available components here. Be responsible please.
import {
    Disclosure,
    DisclosureButton,
    DisclosurePanel,
    TabGroup,
    TabList,
    Tab,
    TabPanels,
    TabPanel,
} from '@headlessui/vue';

createApp({
    delimiters: ['${', '}'],

    components: {
        HuiDisclosure: Disclosure,
        HuiDisclosureButton: DisclosureButton,
        HuiDisclosurePanel: DisclosurePanel,
        HuiTabGroup: TabGroup,
        HuiTabList: TabList,
        HuiTab: Tab,
        HuiTabPanels: TabPanels,
        HuiTabPanel: TabPanel,
    },

    data() {
        return {
            navActive: false,
        };
    },

    mounted() {
        // Because we're using Vue, anything that interacts with the DOM should go here.

        // create the script element to load
        const googleMapScript = document.createElement('script');
        googleMapScript.type = 'text/javascript';
        googleMapScript.src = 'https://maps.googleapis.com/maps/api/js?key=AIzaSyAqMwo3RGRCTcTU4YfZfCD540VhOA76VcI&callback=initMap';
        googleMapScript.setAttribute('defer', '');
        googleMapScript.setAttribute('async', '');
        document.head.appendChild(googleMapScript);

        const mapStyles = [
            {
                featureType: 'administrative',
                elementType: 'all',
                stylers: [
                    {
                        saturation: '-100',
                    },
                ],
            },
            {
                featureType: 'administrative.province',
                elementType: 'all',
                stylers: [
                    {
                        visibility: 'off',
                    },
                ],
            },
            {
                featureType: 'landscape',
                elementType: 'all',
                stylers: [
                    {
                        saturation: -100,
                    },
                    {
                        lightness: 65,
                    },
                    {
                        visibility: 'on',
                    },
                ],
            },
            {
                featureType: 'poi',
                elementType: 'all',
                stylers: [
                    {
                        saturation: -100,
                    },
                    {
                        lightness: '50',
                    },
                    {
                        visibility: 'simplified',
                    },
                ],
            },
            {
                featureType: 'road',
                elementType: 'all',
                stylers: [
                    {
                        saturation: '-100',
                    },
                ],
            },
            {
                featureType: 'road.highway',
                elementType: 'all',
                stylers: [
                    {
                        visibility: 'simplified',
                    },
                ],
            },
            {
                featureType: 'road.arterial',
                elementType: 'all',
                stylers: [
                    {
                        lightness: '30',
                    },
                ],
            },
            {
                featureType: 'road.local',
                elementType: 'all',
                stylers: [
                    {
                        lightness: '40',
                    },
                ],
            },
            {
                featureType: 'transit',
                elementType: 'all',
                stylers: [
                    {
                        saturation: -100,
                    },
                    {
                        visibility: 'simplified',
                    },
                ],
            },
            {
                featureType: 'water',
                elementType: 'geometry',
                stylers: [
                    {
                        hue: '#ffff00',
                    },
                    {
                        lightness: -25,
                    },
                    {
                        saturation: -97,
                    },
                ],
            },
            {
                featureType: 'water',
                elementType: 'labels',
                stylers: [
                    {
                        lightness: -25,
                    },
                    {
                        saturation: -100,
                    },
                ],
            },
        ];


        // Initialize and add the map
        const initMap = () => {

            const maps = document.querySelectorAll('.js-map');

            maps.forEach((map) => {

                // console.log(map);
                const { lat, lng } = JSON.parse(map.dataset.mapBlock);

                const position = {
                    lat: parseFloat(lat),
                    lng: parseFloat(lng),
                };

                console.log(position);
                const gmap = new google.maps.Map(map, {
                    zoom: 16,
                    styles: mapStyles,
                    center: position,
                    scrollwheel: false,
                    mapTypeControl: false,
                    fullscreenControl: false,
                    streetViewControl: false,
                    backgroundColor: 'transparent',
                });

                new google.maps.Marker({
                    position,
                    map: gmap,
                });

                // const location = { lat: -41.4366718158693, lng: 147.14045772794637 };
                // // The map, centered at location
                // const gmap = new google.maps.Map(map, {
                //     zoom: 18,
                //     center: location,
                // });
                // // The marker, positioned at location
                // const marker = new google.maps.Marker({
                //     position: location,
                //     map: gmap,
                // });

            });
        };

        window.initMap = initMap;


        // A minimal responsive menu with just vanilla JS
        new MobileNav();

        // A search box for header. Handles clicking away
        new ClickToggle({
            openSelector: '[data-search-open]',
            activeClass: 'search-open',
            clickAwaySelector: '[data-header-search-form]',
        });
    },
}).mount('#app');
